<template>
  <v-card class="px-4 pt-6 pb-8">
    <v-card-title class="headline mb-9">
      <h3 style="text-align: center; word-break: break-word; display: flex; justify-content: space-between; align-items: center; width: 100%;">
        <span>Ansprechpartner</span>
        <span style="cursor:pointer;" @click="contactRemove">
          <v-icon color="red" size="24">mdi-delete-outline</v-icon>
        </span>
      </h3>
    </v-card-title>
    <v-card-text>
      <v-row v-if="errors">
        <v-col v-if="errors.status === 500">
          <v-alert dense outlined type="error">
            Alle Felder müssen ausgefüllt werden
          </v-alert>
        </v-col>

        <v-col v-if="errors.uniq_contact_error">
          <v-alert dense outlined type="error">
            Dieser Mitarbeiter ist bereits in der Ansprechpartnerliste
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="d-flex">
          <v-text-field v-model="form.first_name" outlined hide-details disabled required
                        :label="$t('employee.contact_person.first_name') + ' *'"
                        :placeholder="$t('employee.contact_person.first_name')"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-text-field v-model="form.last_name" outlined hide-details disabled required
                        :label="$t('employee.contact_person.last_name') + ' *'"
                        :placeholder="$t('employee.contact_person.last_name')"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-text-field v-model="form.contact_email" outlined hide-details required
                        :label="$t('employee.contact_person.email') + ' *'"
                        :placeholder="$t('employee.contact_person.email')"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-text-field v-model="form.contact_phone" outlined hide-details required
                        :label="$t('employee.contact_person.phone') + ' *'"
                        :placeholder="$t('employee.contact_person.phone')"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex">
          <v-text-field v-model="form.position" outlined hide-details required
                        :label="$t('employee.contact_person.position') + ' *'"
                        :placeholder="$t('employee.contact_person.position')"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mr-2">
      <v-btn color="green" :disabled="btn_disable" @click="contactEdit">{{ $t('customer.buttons.save') }}</v-btn>
      <v-btn color="grey" dark @click="dialogClose">{{ $t('customer.buttons.abort') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "CustomerContactPerson",
  props: {
    contact_person: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      btn_disable: false,
      items: [],
      search: null,
      autocomplete_watcher: null,
      search_item_text: 0,
      errors: {},
      form: {
        employee_uid: '',
        customer_id: this.$route.params.id,
        first_name: '',
        last_name: '',
        gender: '',
        contact_email: '',
        contact_phone: '',
        position: ''
      },
      gender_select: [
        {
          text: this.$t('customer.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('customer.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('customer.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('customer.gender.indefinite'),
          value: 'indefinite'
        },
      ],
    }
  },
  created() {
    this.getContact()
    setTimeout(() => {
      this.preloader = false
    }, 300)
  },
  methods: {
    contactRemove() {
      this.$store.dispatch('contactPersonDelete', this.form.id).then(() => {
        this.$emit('contactDeleted', this.form.id)
      })
    },
    getContact() {
      this.form = this.contact_person
    },
    dialogClose() {
      this.$emit('dialogClose', false)
    },
    contactEdit() {
      this.$store.dispatch('contactPersonUpdate', this.form).then(() => {
        this.$emit('getUpdatedPerson', this.$store.getters.customerContactPerson)
        this.$emit('dialogClose', false)
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.snackbar_error = true
            break;
          case 500:
            this.errors = {status: 500, messages: ''};
            this.snackbar_error = true
            break;
        }
      })

    }
  }
}
</script>

<template>
  <v-container fluid>
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'crm.customers'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h1> {{ $t('customer.edit.title') }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-auto"
            elevation="2"
            color="success"
            medium
            @click="userEdit"
        >
          <v-icon size="28">mdi-content-save-outline</v-icon>
        </v-btn>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-1"
            elevation="2"
            color="red"
            dark
            medium
            @click="dialog = true"
        >
          <v-icon size="28">mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8" md="8">
        <v-card class="mb-6">
          <v-card-title>
            {{ $t('customer.titles.personal_data') }}
          </v-card-title>
          <v-card-text>
            <v-form class="pa-4">
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="form.company_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('customer.company_name')"
                                :placeholder="$t('customer.company_name')"
                                :error="errors.company_name!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="d-flex">
                  <v-text-field v-model="form.identification"
                                outlined
                                hide-details
                                disabled
                                required
                                :label="$t('customer.identification') + ' *'"
                                :placeholder="$t('customer.identification')"
                                :error="errors.identification!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.uid"
                                outlined
                                hide-details
                                disabled
                                required
                                :label="$t('customer.uid') + ' *'"
                                :placeholder="$t('customer.uid')"
                                :error="errors.birthday!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.first_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('customer.first_name') + ' *'"
                                :placeholder="$t('customer.first_name')"
                                required
                                :error="errors.first_name!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.last_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('customer.last_name') + ' *'"
                                :placeholder="$t('customer.last_name')"
                                required
                                :error="errors.last_name!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.birthday"
                                outlined
                                hide-details
                                disabled
                                :label="$t('customer.birthday') + ' *'"
                                :placeholder="$t('customer.birthday')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="form.gender"
                            :label="$t('customer.gender.title') + ' *'"
                            :items="gender_select"
                            outlined
                            disabled
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.email"
                                outlined
                                hide-details
                                type="email"
                                :label="$t('customer.email') + ' *'"
                                :placeholder="$t('customer.email')"
                                required
                                :error="errors.email!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.password"
                                outlined
                                hide-details
                                disabled
                                :label="$t('customer.password') + ' *'"
                                :placeholder="$t('customer.password')"
                                required
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-form>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>
            <span>Ansprechpartner</span>
            <v-btn
                style="padding-top: 16px; padding-bottom: 16px;"
                class="ml-auto"
                elevation="2"
                small
                @click="addContactPerson"
            >
              <v-icon size="20">mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-simple-table v-if="contact_persons.length > 0">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  {{ $t('employee.contact_person.first_name') }}
                </th>
                <th class="text-left">
                  {{ $t('employee.contact_person.last_name') }}
                </th>
                <th class="text-left">
                  {{ $t('employee.contact_person.position') }}
                </th>
                <th class="text-left">
                  {{ $t('employee.contact_person.email') }}
                </th>
                <th class="text-left">
                  {{ $t('employee.contact_person.phone') }}
                </th>
              </tr>
              <th></th>
              </thead>
              <tbody>
              <tr v-for="item in contact_persons" :key="item.employee_uid">
                <td>{{ item.first_name }}</td>
                <td>{{ item.last_name }}</td>
                <td>{{ item.position }}</td>
                <td>{{ item.contact_email }}</td>
                <td>{{ item.contact_phone }}</td>
                <td>
                  <span style="cursor: pointer;" @click="contactEdit(item)">
                    <v-icon color="blue" size="16">mdi-pencil-outline</v-icon>
                  </span>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4">
        <v-card class="mb-6">
          <v-card-title>{{ $t('customer.titles.settings') }}</v-card-title>
          <v-card-text>
            <v-switch
                v-model="form.email_send"
                :label="$t('customer.email_send')"
                color="primary"
            ></v-switch>
            <v-switch
                v-model="form.is_blocked"
                :label="$t('customer.is_blocked')"
                color="primary"
            ></v-switch>

            <v-switch
                v-model="form.can_faq"
                :label="$t('customer.can_faq')"
                color="primary"
            ></v-switch>
            <v-switch
                v-model="form.multi_company"
                :label="$t('customer.multi_company')"
                color="primary"
            ></v-switch>
          </v-card-text>
        </v-card>
        <v-card class="mb-6" style="padding-bottom: 11px;">
          <v-card-title>{{ $t('customer.titles.password') }}</v-card-title>
          <v-card-text>
            <PasswordGenerator :status_auto_generate="false" @getPassword="getPassword"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar_error"
        top
        right
        color="red"
    >
      <template v-if="errors.uid!==undefined">
        {{ $t('customer.create.errors.user_hast') }}
      </template>
      <template v-else>
        {{ $t('customer.create.errors.required_fields') }}
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="500"
      >
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center; word-break: break-word;">
              {{ $t('customer.titles.customer_delete') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="userDelete">
              {{ $t('customer.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="dialog = false">
              {{ $t('customer.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog_create_contact" persistent max-width="900">
        <CustomerContactPersonCreateForm v-if="dialog_create_contact" :contact_persons="contact_persons" @dialogClose="dialogClose" @getPerson="getPerson" />
      </v-dialog>

      <v-dialog v-model="dialog_edit_contact" persistent max-width="900">
        <CustomerContactPersonEditForm
            v-if="dialog_edit_contact"
            :contact_person="contact_person"
            @dialogClose="dialogClose"
            @getUpdatedPerson="getUpdatedPerson"
            @contactDeleted="contactDeleted"
        />
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import PasswordGenerator from "@/components/PasswordGenerator";
import CustomerContactPersonCreateForm from "@/components/Customer/CustomerContactPersonCreateForm";
import CustomerContactPersonEditForm from "@/components/Customer/CustomerContactPersonEditForm";
import moment from "moment";

export default {
  components: {PasswordGenerator, CustomerContactPersonCreateForm, CustomerContactPersonEditForm},
  data() {
    return {
      preloader: true,
      snackbar_error: false,
      dialog: false,
      dialog_create_contact: false,
      dialog_edit_contact: false,
      contact_person: {},
      form: {
        identification: '',
        uid: '',
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: null,
        gender: 'female',
        is_blocked: 0,
        email_send: 0,
        multi_company: 0,
        can_faq: 0,
        avatar: null
      },
      contact_persons: [],
      gender_select: [
        {
          text: this.$t('customer.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('customer.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('customer.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('customer.gender.indefinite'),
          value: 'indefinite'
        },
      ],
      errors: {},
    }
  },
  mounted() {
    this.getItem()
    setTimeout(() => {this.preloader = false}, 300)
  },
  methods: {
    contactDeleted(id) {
      if (this.contact_persons.length > 1) {
        let index = this.contact_persons.findIndex(c => c.id === id)
        this.contact_persons.splice(index,1)
      } else {
        this.contact_persons = []
      }
      this.dialog_edit_contact = false
    },
    addContactPerson() {
      this.dialog_create_contact = true
    },
    contactEdit(contact) {
      this.contact_person = contact
      this.dialog_edit_contact = true
    },
    dialogClose() {
      this.dialog_create_contact = false
      this.dialog_edit_contact = false
    },
    getPerson(e) {
      this.contact_persons.push(e)
    },
    getUpdatedPerson(e) {
      let index = this.contact_persons.findIndex(c => c.id === e.id)
      this.contact_persons[index] = e
    },
    getItem() {
      this.$store.dispatch('customerCrmEdit', this.$route.params.id).then(() => {
        const user = this.$store.getters.customerCrm.customer
        this.contact_persons = JSON.parse(this.$store.getters.customerCrm.contact_persons).data

        this.form.id = user.id
        this.form.uid = user.uid
        this.form.identification = user.identification
        this.form.company_name = user.company_name
        this.form.first_name = user.first_name
        this.form.last_name = user.last_name
        this.form.birthday = this.getBirthday(user.birthday)
        this.form.gender = user.gender
        this.form.email = user.email
        this.form.is_blocked = this.userGetBlocked(user.user_blocked)
        this.form.can_faq = user.can_faq
        this.form.multi_company = user.multi_company
        this.form.avatar = user.avatar
      })
    },
    getPassword(password) {
      this.form.password = password
    },
    userEdit() {

      const user = {
        id: this.form.id,
        email: this.form.email,
        email_send: this.form.email_send,
        password: this.form.password,
        can_faq: this.form.can_faq,
        user_blocked: this.userSetBlocked(this.form.is_blocked),
        multi_company: this.form.multi_company,
      }
      this.$store.dispatch('customerCrmUpdate', user).then(() => {
        this.$router.replace({
          name: 'crm.customers'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.snackbar_error = true
            break;
        }
      })
    },
    userDelete() {
      this.$store.dispatch('customerCrmDelete', this.form.id).then(() => {
        this.$router.replace({
          name: 'crm.customers'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    },
    getBirthday(date,format='YYYY-MM-DD') {
      return moment(date, format).format('DD.MM.YYYY')
    },
    userGetBlocked(str) {
      return str === 'blocked' ? 1 : 0
    },
    userSetBlocked(bool) {
      return bool === false || bool === 0 ? 'not_blocked' : 'blocked'
    }
  }
}
</script>
